import DialogPolyfill from 'dialog-polyfill'
import Cookies from 'cookies-js'

var dialog = document.getElementById('fundraising-dialog')
if (dialog) {
  DialogPolyfill.registerDialog(dialog)
}

// @todo this can be removed after 2024-01-01, just cleans up the old cookie with a different duration
Cookies.expire('fundraisingDialogClosed')

function setClosedCookie() {
  Cookies.set('wasFundraisingDialogClosed', '1', {
    expires: 24 * 60 * 60, // 1 day
  })
}

if (dialog && window.innerWidth >= 720) {
  var buttonNodeList = dialog.querySelectorAll('[data-dialog-close-button]') || []
  var buttonList = [].slice.call(buttonNodeList)

  buttonList.forEach(function (button) {
    button.addEventListener('click', function () {
      dialog.close()
    })
  })

  var dialogLink = dialog.querySelector('[data-dialog-link]')
  if (dialogLink) {
    dialogLink.addEventListener('click', function () {
      dialog.close()
    })
  }

  dialog.addEventListener('click', function (e) {
    var isBackdrop = e.target.nodeName === 'DIALOG'
    var isBackdropPolyfill = e.target.classList.contains('backdrop')
    if (isBackdrop || isBackdropPolyfill) {
      dialog.close()
    }
  })

  dialog.addEventListener('close', setClosedCookie)

  dialog.showModal()
}
